import React, { useState, useEffect, useRef } from "react";
import AppLayout from "../../../components/applayout/AppLayout";
import BroadcastNow from "../broadcast-now";
import useToken from "../../../hooks/useToken";
import interceptor, { baseUrl } from "../../../hooks/intercepter.js";
import toast from "react-hot-toast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { AddContentIcon, BroadcastIcon, DashMonitorIcon, MonitorIcon } from "../../../assets/icons/Icons";
import { contentEventEmitter } from "../../../hooks/event-emitter";
import { useTranslation } from "react-i18next";
import { io } from "socket.io-client";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { Textarea } from "../../../components/ui/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import StatusToggle from "../../admin/users/StatusToggle";
import Draggable from "react-draggable";

const Dashboard = () => {
  const { t } = useTranslation("translation");
  const videoRef = useRef(null);
  const imageRef = useRef(null);
  const { token, user } = useToken();
  const FormSchema = z.object({
    name: z.string().min(1, { message: "Name is required" }),
    location_name: z.string().min(1, { message: "Screen Code is required" }),
    location_address: z.string().min(1, { message: "Screen address is required" }),
  });
  const [contentList, setContentList] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const currentVideoIndexRef = useRef(0);
  const [contentType, setContentType] = useState(null);
  const [isBroadcasting, setIsBroadcasting] = useState(false);
  const imageTimerRef = useRef(null); // Timer reference for image
  const [loading, setLoading] = useState(true);
  const [broadcastduration, setBroadcastduration] = useState();
  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
      location_name: "",
      location_address: "",
    },
  });
  const [showModal, setShowModal] = useState(false);
  const [showDurModal, setShowDurModal] = useState(false);
  const [screenId, setScreenId] = useState(null);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleModalDurSave();
    }
  };

  const handleModalDurSave = () => {
    form.handleSubmit((values) => {
      setShowDurModal(false);
      let modal = values
      if (screenId) { modal = { ...modal, id: screenId } }
      let url = screenId ? `users/broadcaster/${user?.id}/broadcaster-screen/${screenId}` : `users/broadcaster/${user?.id}/broadcaster-screen`;
      try {
        interceptor.axiosPut(screenId ? "PUT" : "Post", url,
          modal, token
        ).then((response) => {
          if (response.status === 200) {
            socketRef.current.on("connect", () => {
              console.log("Connected to WebSocket server");
              console.log("Room has been joined");
              socketRef.current.emit("join", { room: modal.location_name });
            });

            socketRef.current.emit("server_message", { 'message': { 'room': modal.location_name, 'data': 'status', 'is_screen_connected': true }, 'room': modal.location_name });
            fetchScreenList();
            toast.success("Screen Updated Successfully");
          }
        })
      } catch (error) {
        toast.error(error?.message);
        console.error("Update request failed:", error);
      }
    })();
  };

  const handleModalClose = () => {
    setShowModal(false);
    setShowDurModal(false);
    setScreenId(null);
  };

  useEffect(() => {
    getFileDuration();
    getChangeQrStatus();
    getStatus();
    if (contentList.length === 0) { fetchContent(); }
  }, []);

  useEffect(() => {
    if (contentList.length === 0) { fetchContent(); }
  }, []);

  const getFileDuration = (value) => {
    console.log("dashboard")
    try {
      interceptor.axiosGet("get", `packages/content-duration`).then((res) => {
        if (res?.status === 200) {
          console.log(res?.data?.data?.content_duration)
          setBroadcastduration(res?.data?.data?.content_duration);
        }

      });

    } catch (error) {
      console.error("Failed to fetch packages:", error);
    }
  }

  useEffect(() => {
    // const onContentUpdated = () => {
    //   fetchContent();
    // };
    // contentEventEmitter.on("contentUpdated", onContentUpdated);
    // return () => {
    //   contentEventEmitter.off("contentUpdated", onContentUpdated);
    // };
  }, []);

  const fetchContent = async () => {
    try {
      const response = await interceptor.axiosGet(
        "GET",
        `users/combined-content/${user.id}`,
        token
      );
      if (response.status) {
        const newContentList = response.data.data.result;
        setContentList(newContentList);
        debugger
        setContentType(newContentList[0]?.content_type);
        console.log("abc", contentList);
        currentVideoIndexRef.current = 0;
        if (isBroadcasting && currentVideoIndexRef.current >= newContentList.length) {
          currentVideoIndexRef.current = 0; // Reset to first content if index is out of bounds
        }
        // if (isBroadcasting) {
        //   loadContent(newContentList[0]);
        // }
        return newContentList
      }
    } catch (error) {
      console.error("Failed to fetch content:", error);
      toast.error("Failed to fetch content. Please try again.");
    }
  };

  const handleBroadcastNow = () => {

    // fetchContent().then(async (response) => {
    if (items.length > 0) {
      const ids = items.filter(val => val.check || val.is_broadcast).map(val => val.id);
      if (ids && ids.length > 0) {
        interceptor.axiosPost(
          'users/update-screen-status',
          { 'screen_id': ids, 'status': true },
          token,
        ).then(async (response) => {
          if (response.status === 200) {
            debugger
            await fetchScreenList();
            // currentVideoIndexRef.current = 0;
            // const currentContent = contentList[0];
            // setContentType(currentContent?.content_type);
            // setIsBroadcasting(true);
            // handleCountStart()
          }
        })
      }

    } else {
      toast.error("No screen available to broadcast.");
    }
    // });
  };

  const stopBroadcastNow = () => {
    const ids = items.filter(val => val.is_broadcast && !val.check).map(val => val.id);
    interceptor.axiosPost(
      `users/update-screen-status`,
      { 'screen_id': ids, 'status': true },
      token
    ).then((response) => {
      if (response.status === 200) {
        fetchScreenList();
        items.forEach((screen) => {
          if (screen.is_broadcast && screen.check) {
            if (socketRef.current) {
              if (!socketRef.current.hasConnectedListener) {
                socketRef.current.on("connect", () => {
                  console.log("Connected to WebSocket server");
                });
                socketRef.current.hasConnectedListener = true;
              }
              console.log("Room has been joined:", screen.location_name);
              socketRef.current.emit("join", { room: screen.location_name });
              socketRef.current.emit("server_message", { 'message': { 'room': screen.location_name, 'data': 'status', 'screen_code': screen.location_name, 'broadcaster_id': user?.id, 'token': token, screen_status: 0 }, 'room': screen.location_name });
            } else {
              console.error("WebSocket not initialized");
            }
          }
        });

      }
    })

  }

  const updateCount = async () => {
    interceptor.axiosPut(
      "PUT",
      `users/broadcast-count/broadcast`, '',
      token
    );
  };

  const handleVideoEnded = async () => {
    if (currentVideoIndexRef.current + 1 >= contentList.length) {
      await fetchContent().then((response) => {
        currentVideoIndexRef.current = 0;
        setCurrentVideoIndex(0);
        const nextContent = response[currentVideoIndexRef.current];
        setContentType(nextContent?.content_type);
        // loadContent(nextContent);
      });
    } else {
      currentVideoIndexRef.current =
        (currentVideoIndexRef.current + 1) % contentList.length;
      setCurrentVideoIndex(currentVideoIndexRef.current);
      const nextContent = contentList[currentVideoIndexRef.current];
      setContentType(nextContent?.content_type);
      loadContent(nextContent);
    }
  };

  const textRef = useRef(null);
  const [isFading, setIsFading] = useState(false);
  const loadContent = (currentContent) => {
    if (!currentContent) return;

    if (textRef.current) textRef.current.textContent = ''
    setContentType(currentContent.content_type);
    const duration =
      currentContent.source === "advertiser" &&
        currentContent.package_details?.content_duration
        ? currentContent.package_details.content_duration * 1000
        : broadcastduration * 1000;

    if (["png", "jpg", "jpeg", "qr_code"].includes(currentContent.content_type)) {
      setLoading(true);

      const tempImage = new Image();
      // tempImage.src = `${baseUrl}media/${currentContent.content_path}`;
      tempImage.src = `${baseUrl}media/${currentContent.content_path}?t=${new Date().getTime()}`; // Cache buster
      tempImage.onload = () => {

        let eme = document.getElementById('image-container')
        // Remove existing image if it exists
        if (eme && eme.firstChild) {
          eme.removeChild(eme.firstChild);  // Remove the first child (previous image)
        }

        // if (imageRef.current) {
        setLoading(false);
        // imageRef.current.classList.remove("w3-animate-zoom");
        // void imageRef.current.offsetWidth;  // Trigger reflow
        // imageRef.current.classList.add("w3-animate-zoom");
        // imageRef.current.src = tempImage.src;

        const newImg = document.createElement("img");
        newImg.src = tempImage.src;  // Set the loaded image source
        newImg.alt = "Content";
        newImg.classList.add("object-contain", "w-full", "h-full", "w3-animate-zoom");

        document.getElementById('image-container').appendChild(newImg); // Assuming you have an element with ID 'image-container'
        // const duration = currentContent.package_details.content_duration * 1000
        handleUpdateFrequency(
          currentContent.id,
          currentContent.source,
          currentContent.content_type
        );

        setTimeout(() => {
          handleVideoEnded();
        }, duration);
        // }
      };

      tempImage.onerror = () => {
        console.error("Failed to load image:", tempImage.src);
        handleVideoEnded();
        setLoading(false);
      };


    } else if (["mp4", "mov", "gif"].includes(currentContent.content_type)) {
      handleUpdateFrequency(
        currentContent.id,
        currentContent.source,
        currentContent.content_type
      );

      if (videoRef.current) {

        videoRef.current.src = `${baseUrl}media/${currentContent.content_path}`;
        videoRef.current.classList.remove("w3-animate-zoom");
        void videoRef.current.offsetWidth; // Trigger reflow
        videoRef.current.classList.add("w3-animate-zoom");

        videoRef.current.load();
        videoRef.current.play().catch((error) => {
          console.error("Failed to play video:", error);
        });
      }
    } else if (currentContent.content_type === "text") {
      // Handle displaying text
      if (textRef.current) {
        // Trigger fade-out
        setIsFading(true);

        // Set new text content after fade-out
        setTimeout(() => {
          try {
            const content = JSON.parse(currentContent.content_path);
            textRef.current.innerHTML = content || "No content available";
          } catch (error) {
            textRef.current.innerHTML = currentContent.content_path;
          }
          setIsFading(false); // Trigger fade-in
        }, 200); // Match fade-out duration in CSS

        const duration =
          currentContent.source === "advertiser" &&
            currentContent.package_details?.content_duration
            ? currentContent.package_details.content_duration * 1000
            : broadcastduration * 1000;

        handleUpdateFrequency(
          currentContent.id,
          currentContent.source,
          currentContent.content_type
        );

        setTimeout(() => {
          handleVideoEnded();
        }, duration);

      }
    }

  };

  useEffect(() => {
    if (isBroadcasting && contentList.length > 0) {
      const currentContent = contentList[currentVideoIndexRef.current];
      loadContent(currentContent);
    }
    return () => {
      if (imageTimerRef.current) {
        clearTimeout(imageTimerRef.current); // Clear timeout on unmount or content change
      }
    };
  }, [isBroadcasting, contentList]);

  const handleUpdateFrequency = async (id, source, content_type) => {
    if (source === "advertiser") {
      try {
        const response = await interceptor.axiosPut(
          "PATCH",
          `users/update-content-frequency/${id}`,
          "",
          token
        );

        if (response.status === 200) {
          console.log("Content frequency updated successfully");
        }
      } catch (error) {
        console.error("Update request failed:", error);
      }
    }
  };

  useEffect(() => {
    fetchScreenList();
  }, []);
  const [items, setItems] = useState([]);

  const fetchScreenList = async () => {
    try {
      await interceptor.axiosGet(
        "get",
        `users/broadcaster/${user?.id}/broadcaster-screen`,
        token
      ).then((response) => {
        if (response.status === 200) {
          setItems(response?.data?.data?.screens || []);
          response?.data?.data?.screens.forEach((screen) => {
            debugger
            if (screen.is_broadcast) {
              if (socketRef.current) {
                if (!socketRef.current.hasConnectedListener) {
                  socketRef.current.on("connect", () => {
                    console.log("Connected to WebSocket server");
                  });
                  socketRef.current.hasConnectedListener = true;
                }
                console.log("Room has been joined:", screen.location_name);
                socketRef.current.emit("join", { room: screen.location_name });
                socketRef.current.emit("server_message", { 'message': { 'room': screen.location_name, 'data': 'status', 'screen_code': screen.location_name, 'broadcaster_id': user?.id, 'token': token, screen_status: 1 }, 'room': screen.location_name });
              } else {
                console.error("WebSocket not initialized");
              }
            }
          });
        }
      })
    } catch (error) {
      console.error("Failed to fetch screens:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleFullScreen = (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      // Firefox
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      // IE/Edge
      element.msRequestFullscreen();
    }
  };
  const socketRef = useRef();
  useEffect(() => {
    socketRef.current = io("https://staging.speedfame.fr");
    console.log("Attempting to connect...");
    socketRef.current.on("server_message", (data) => {
      console.log(
        `Message from server: ${data.message.data}, is_screen_connected: ${data.message.is_screen_connected}`
      );
    });

    return () => {
      socketRef.current.disconnect();
      console.log("Disconnected from WebSocket server");
    };
  }, []);


  const handleCountStart = async () => {
    try {
      await interceptor.axiosPut(
        "PUT",
        `users/broadcast-count/broadcast`,
        "",
        token
      );
      setIsBroadcasting(true);
      // toggleFullScreen(document.getElementById("full-screenVideo"));
    } catch (error) {
      console.error("Update request failed:", error);
    }
  };

  const handleCountEnd = async () => {
    try {
      await interceptor.axiosPut(
        "PUT",
        `users/broadcast-count/cancel`,
        "",
        token
      );

      items.map((screen) => {
        if (screen.check) {
          if (socketRef.current) {
            if (!socketRef.current.hasConnectedListener) {
              socketRef.current.on("connect", () => {
                console.log("Connected to WebSocket server");
              });
              socketRef.current.hasConnectedListener = true;
            }
            console.log("Room has been joined:", screen.location_name);
            socketRef.current.emit("join", { room: screen.location_name });
            socketRef.current.emit("server_message", { 'message': { 'room': screen.location_name, 'data': 'status', 'screen_code': screen.location_name, 'broadcaster_id': user?.id, 'token': token, screen_status: 0 }, 'room': screen.location_name });

          } else {
            console.error("WebSocket not initialized");
          }
        }
      });

      setIsBroadcasting(false); // Stop broadcasting
    } catch (error) {
      console.error("Update request failed:", error);
    }
  };

  const toggleCheck = (index) => {
    const updatedList = items.map((item, ind) =>
      ind === index ? { ...item, check: !item.check } : item
    );
    setItems(updatedList);
  };

  const [status, setStatus] = useState();
  const handleChangeStatus = async () => {
    try {
      const response = await interceptor.axiosPut("PUT", `content-validation-settings`,
        {
          content_validation_enabled: !status,
          user_id: user?.id
        }, token
      );
      if (response.status === 200) {
        setStatus(response?.data.data.content_validation_enabled)
        toast.success("Status Updated Successfully");
      }
    } catch (error) {
      toast.error(error?.message);
      console.error("Update request failed:", error);
    }
  };

  const getStatus = async () => {
    await interceptor.axiosGet("GET", `content-validation-settings?user_id=${user.id}`, token
    ).then((res) => {
      debugger
      if (res.status === 200) {
        setStatus(res?.data.data.content_validation_enabled)
      }
    })
  };

  const [statusQr, setStatusQr] = useState();
  const handleChangeQrStatus = async () => {
    try {
      const response = await interceptor.axiosPut("Post", `users/broadcaster/qrcode`,
        {
          is_qrcode_show: !statusQr,
          user_id: user?.id
        }, token
      );
      if (response.status === 200) {
        setStatus(response?.data.data.is_qrcode_show)
        getChangeQrStatus();
        toast.success("Status Updated Successfully");
      }
    } catch (error) {
      toast.error(error?.message);
      console.error("Update request failed:", error);
    }
  };
  const getChangeQrStatus = async () => {
    try {
      const response = await interceptor.axiosGet("Get", `users/broadcaster/qrcode?user_id=${user?.id}`,
        token
      );
      debugger
      if (response.status === 200) {
        setStatusQr(response?.data.data.is_qrcode_show)
      }
    } catch (error) {
      toast.error(error?.message);
      console.error("Update request failed:", error);
    }
  };

  return (
    <AppLayout>
      <h5 className="text-lg font-semibold border-b border-light-gray pb-2.5">
        {t("dashboard")}
      </h5>
      <div className="float-right">
        <div className="px-8 mb-4 w-[15remrem] valition ">
          <span className="px-3  mr-3 pt-1 font-medium text-sm" style={{ verticalAlign: 'middle' }}> {t("enableQr")}</span>
          <StatusToggle
            className="ml-3"
            isActive={statusQr}
            onToggle={() => handleChangeQrStatus()}
          />
        </div>
        <div className="px-8 mb-4 w-[15rem] valition">
          <span className="px-3 mr-3 pt-1 font-medium text-sm" style={{ verticalAlign: 'middle' }}>{t("validateContent")}</span>
          <StatusToggle
            isActive={status}
            onToggle={() => handleChangeStatus()}
          />
        </div>
      </div>
      <div className="flex flex-col items-center gap-7 my-12">
        <h3 className="text-3xl font-bold capitalize">
          {t("welconmeDashboar")}
        </h3>


      </div>

      <div className="flex flex-wrap items-center justify-start gap-3  sm:my-4">
        <h3 className="text-2xl font-bold mr-4" style={{ lineHeight: '32.3px' }}>
          Select the Screens You want to broadcast On
        </h3>
        <Button
          size="sm"
          className="px-8 w-fit bg-green text-white"
          onClick={() => setShowDurModal(true)}
        >
          {t("addscreen")}
        </Button>
        {/* <Button
          size="sm"
          className="px-8 w-fit bg-red text-white"
        >
          Delete Screen
        </Button> */}
      </div>
      {items && items.some(val => val.is_broadcast) && (
        <div className="flex gap-7 items-left rounded-xl bg-gray-100 mb-[1rem]">
          <ul className="flex flex-wrap gap-4 px-7 py-4 h-full items-center justify-left">
            {items.map((val, ind) =>
              val.is_broadcast ? (
                <li key={ind} onClick={() => toggleCheck(ind)}>
                  <div
                    className={`flex items-center gap-3 p-4 rounded-xl w-[14rem] h-[5rem] ${val.check ? "bg-blue text-white" : "bg-gray-300"
                      }`}
                  >
                    <DashMonitorIcon
                      className={`${val.check ? "stroke-white" : "stroke-dark"}`}
                      stroke={`${val.check ? "stroke-white" : "stroke-dark"}`}
                      width={48}
                      height={48}
                    />
                    <span>{val.name}</span>
                  </div>
                </li>
              ) : null
            )}
            <li className="">
              <div
                className="bg-blue rounded-xl text-white text-sm font-medium px-8 py-3.5"
                onClick={stopBroadcastNow}
              >
                {t("stopBroadCast")}
              </div>

            </li>
          </ul>
        </div>
      )}

      <div className="flex  gap-7 item-left  rounded-xl bg-gray-100 mb-[10rem]" >
        <ul className="flex  flex-wrap gap-4 px-7 py-4   h-full items-center justify-left">
          {items && items.map((val, ind) => {
            return (
              <>
                {!val?.is_broadcast && <li key={ind} onClick={() => toggleCheck(ind)}>
                  <div
                    className={`flex items-center gap-3 p-4 rounded-xl w-[14rem] h-[5rem] ${val.check ? "bg-blue text-white" : "bg-gray-300"}`}
                  >
                    <DashMonitorIcon
                      className={`${val.check ? "stroke-white" : "stroke-dark"}`}
                      stroke={`${val.check ? "stroke-white" : "stroke-dark"}`}
                      width={48}
                      height={48}
                    />
                    <span className="">{val.name}</span>
                  </div>
                </li>
                }
              </>
            );
          })}
          <li className="">
            <div
              className="bg-blue rounded-xl text-white text-sm font-medium px-8 py-3.5"
              onClick={handleBroadcastNow}
            >
              {t("braodcastNow")}
            </div>

          </li>
        </ul>
      </div>

      <div
        className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center ${showDurModal ? "" : "hidden"
          }`}
      >
        <div className="md:w-6/12 w-full lg:mx-0 md:mx-auto sm:mt-8 mt-6 mb-24">
          <div className="flex flex-col gap-5">
            <div className="bg-background rounded-xl sm:p-8 p-5">
              <label className="text-base font-medium">{t("addscreen")}</label>
              <Form {...form}>
                <div className="flex flex-col gap-4 mt-5">
                  <div>
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem className="space-y-0">
                          <FormControl>
                            <Input
                              type="text"
                              className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl"
                              placeholder={t("screenName")}
                              name="name"
                              {...field}
                              onKeyPress={handleKeyPress}

                            />
                          </FormControl>
                          <FormMessage className="text-red md:font-semibold mt-2 inline-block" />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={form.control}
                      name="location_name"
                      render={({ field }) => (
                        <FormItem className="space-y-0">
                          <FormControl>
                            <Input
                              type="text"
                              className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl"
                              placeholder={t("code")}
                              {...field}
                              onKeyPress={handleKeyPress}

                            />
                          </FormControl>
                          <FormMessage className="text-red md:font-semibold mt-2 inline-block" />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={form.control}
                      name="location_address"
                      render={({ field }) => (
                        <FormItem className="space-y-0">
                          <FormControl>
                            <Textarea
                              type="text"
                              className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl"
                              placeholder={t("address")}
                              {...field}
                              onKeyPress={handleKeyPress}

                            ></Textarea>
                          </FormControl>
                          <FormMessage className="text-red md:font-semibold mt-2 inline-block" />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="text-right">
                    <Button
                      size="sm"
                      className="w-50 px-12 mr-5 bg-gray-300 text-gray-700"
                      // className="px-4 py-2 text-gray-700 bg-gray-300 rounded"
                      onClick={() => handleModalClose()}
                    >
                      Close
                    </Button>
                    <Button
                      size="sm"
                      className="w-50 px-12"

                      onClick={handleModalDurSave}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </AppLayout >
  );
};

export default Dashboard;
