import React, { createContext, useEffect } from "react";
import webSocketService from "./webSocketService";

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    let user = JSON.parse(localStorage.getItem('@tokenData'))
    debugger
    useEffect(() => {
        if (user?.id) {
            // Connect to WebSocket server with userId
            webSocketService.connect("https://staging.speedfame.fr", user.id);
        }

        return () => {
            // Optional: Disconnect only if necessary
            // webSocketService.disconnect();
        };
    }, [user?.id]);

    return (
        <WebSocketContext.Provider value={webSocketService}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => React.useContext(WebSocketContext);
