import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTrigger,
} from "../../../components/ui/video-dialog";
import interceptor from "../../../hooks/intercepter";
import useToken from "../../../hooks/useToken";
import toast from "react-hot-toast";

const BroadcastNow = ({ baseUrl }) => {
  const [contentList, setContentList] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [contentType, setContentType] = useState(null);
  const [isBroadcasting, setIsBroadcasting] = useState(false);
  const videoRef = useRef(null);
  const imageRef = useRef(null);
  const imageTimerRef = useRef(null); // Timer reference for image
  const { token, user } = useToken();

  const fetchContent = async () => {
    try {
      const response = await interceptor.axiosGet(
        "GET",
        `users/combined-content/${user.id}`,
        token
      );
      if (response.status) {
        const newContentList = response.data.data.result;
        setContentList(newContentList);
        if (isBroadcasting && currentVideoIndex >= newContentList.length) {
          setCurrentVideoIndex(0); // Reset to first content if index is out of bounds
        }
        if (isBroadcasting) {
          loadContent(newContentList[currentVideoIndex]);
        }
      }
    } catch (error) {
      console.error("Failed to fetch content:", error);
      toast.error("Failed to fetch content. Please try again.");
    }
  };

  const handleUpdateFrequency = async (id, source, content_type) => {
    if (source === "advertiser") {
      try {
        const response = await interceptor.axiosPut(
          "PATCH",
          `users/update-content-frequency/${id}`,
          "",
          token
        );
        if (response.status === 200) {
          console.log("Content frequency updated successfully");
        }
      } catch (error) {
        console.error("Update request failed:", error);
      }
    } else if (source === "broadcaster" || content_type === "qr_code") {
      await fetchContent();
    }
  };

  const loadContent = (currentContent) => {
    if (!currentContent) return;

    setContentType(currentContent.content_type);

    if (["png", "jpg", "jpeg", "qr_code"].includes(currentContent.content_type)) {
      // Preload the image
      const tempImage = new Image();
      tempImage.src = `${baseUrl}media/${currentContent.content_path}`;
  
      tempImage.onload = () => {
        if (imageRef.current) {
          // Set the src of the actual image element to the preloaded image
          imageRef.current.src = tempImage.src;
  
          // Clear any existing timer
          if (imageTimerRef.current) {
            clearTimeout(imageTimerRef.current);
          }
  
          // Apply the animation
          imageRef.current.classList.remove("w3-animate-zoom");
          void imageRef.current.offsetWidth;  // Trigger reflow
          imageRef.current.classList.add("w3-animate-zoom");
  
          // Set up the timer to move to the next content
          const duration =
            currentContent.source === "advertiser" &&
            currentContent.package_details?.content_duration
              ? currentContent.package_details.content_duration * 1000
              : 10000;
  
          imageTimerRef.current = setTimeout(() => {
            handleUpdateFrequency(
              currentContent.id,
              currentContent.source,
              currentContent.content_type
            );
          }, duration);
        }
      };
  
      tempImage.onerror = () => {
        console.error("Failed to load image:", tempImage.src);
      };
    } else if (["mp4", "mov", "gif"].includes(currentContent.content_type)) {
      handleUpdateFrequency(
        currentContent.id,
        currentContent.source,
        currentContent.content_type
      );

      if (videoRef.current) {
        videoRef.current.src = `${baseUrl}media/${currentContent.content_path}`;
        videoRef.current.classList.remove("w3-animate-zoom");
        void videoRef.current.offsetWidth; // Trigger reflow
        videoRef.current.classList.add("w3-animate-zoom");
  
        videoRef.current.load();
        videoRef.current.play().catch((error) => {
          console.error("Failed to play video:", error);
        });
      }
    }
  };

  const handleContentEnd = async (isImage = false) => {
    if (isBroadcasting) {
      const currentContent = contentList[currentVideoIndex];
      await handleUpdateFrequency(
        currentContent?.id,
        currentContent?.source,
        currentContent?.content_type
      );
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % contentList.length);
    }
  };

  useEffect(() => {
    if (isBroadcasting && contentList.length > 0) {
      // Adjust current index if it goes out of bounds
      if (currentVideoIndex >= contentList.length) {
        setCurrentVideoIndex(0);
      }
      const currentContent = contentList[currentVideoIndex];
      loadContent(currentContent);
    }
    return () => {
      if (imageTimerRef.current) {
        clearTimeout(imageTimerRef.current); // Clear timeout on unmount or content change
      }
    };
  }, [isBroadcasting, currentVideoIndex, contentList]);

  useEffect(() => {
    if (isBroadcasting && contentList.length > 0) {
      const currentContent = contentList[currentVideoIndex];
      loadContent(currentContent);
    }
  }, [contentList]);

  const toggleFullScreen = (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      // Firefox
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      // IE/Edge
      element.msRequestFullscreen();
    }
  };

  const handleCountStart = async () => {
    try {
      await interceptor.axiosPut(
        "PUT",
        `users/broadcast-count/broadcast`,
        "",
        token
      );
      setIsBroadcasting(true);
      fetchContent();
      toggleFullScreen(document.getElementById("full-screenVideo"));
      updateCount();
    } catch (error) {
      console.error("Update request failed:", error);
    }
  };

  const updateCount = async () => {
    interceptor.axiosPut(
      "PUT",
      `users/broadcast-count/broadcast`,'',
      token
    );
  };

  const handleCountEnd = async () => {
    try {
      await interceptor.axiosPut(
        "PUT",
        `users/broadcast-count/cancel`,
        "",
        token
      );
      setIsBroadcasting(false); // Stop broadcasting
    } catch (error) {
      console.error("Update request failed:", error);
    }
  };

  return (
    <Fragment>
      <Dialog>
        <DialogTrigger
          className="bg-blue rounded-xl text-white text-sm font-medium px-8 py-3.5"
          onClick={handleCountStart}
        >
          Diffuser maintenant
        </DialogTrigger>

        <DialogContent
          strokeClr="white"
          className="bg-black border-none rounded-none min-w-full w-full h-screen flex items-center justify-center"
          handleCountEnd={handleCountEnd}
          id="full-screenVideo"
        >
          <DialogDescription className="h-screen w-screen">
            {isBroadcasting && contentList.length > 0 && (
              <>
                {["mov", "mp4", "gif"].includes(contentType) && (
                  <video
                    ref={videoRef}
                    className="object-contain sm:object-cover w-full h-full w3-animate-zoom"
                    autoPlay
                    muted
                    onEnded={() => handleContentEnd()}
                  >
                    <source
                      src={`${baseUrl}${contentList[currentVideoIndex]?.content_path}`}
                      type={`video/${contentType}`}
                    />
                    Your browser does not support the video tag.
                  </video>
                )}
                {["png", "jpg", "jpeg", "qr_code"].includes(contentType) && (
                  <img
                    ref={imageRef}
                    src={`${baseUrl}${contentList[currentVideoIndex]?.content_path}`}
                    alt="Content"
                    className="object-contain sm:object-cover w-full h-full w3-animate-zoom"
                  />
                )}
              </>
            )}
          </DialogDescription>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default BroadcastNow;
