// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input:focus {
  outline: none !important;
  border: none !important;
}
.content-modal ::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.content-modal ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.content-modal ::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.content-modal ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.PhoneInputInternationalIconPhone{
/* display: none !important; */
margin-left: 6px;
}

.PhoneInputInternationalIconGlobe{
  display: none !important;
}

.PhoneInputCountry input{
background-color: #F7F7F7 !important;
}

.PhoneInputInput{
  background-color: #F7F7F7 !important;
}

.contact-form .PhoneInputInput{
  background-color: white !important;
}


/* 
.broadcast video,
img {
  width: 100%;
  height: auto;
}

.broadcast video:-webkit-full-screen,
img:-webkit-full-screen {
  width: 100%;
  height: 100%;
}

.broadcast video:-moz-full-screen,
img:-moz-full-screen {
  width: 100%;
  height: 100%;
}

.broadcast video:-ms-fullscreen,
img:-ms-fullscreen {
  width: 100%;
  height: 100%;
}

.broadcast video:fullscreen,
img:fullscreen {
  width: 100%;
  height: 100%;
} */


.valition{
  background: black;
  padding: 10px;
  border-radius: 10px;
  vertical-align: middle;
  display:flex;
  color:#F7F7F7
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,uBAAuB;AACzB;AACA;EACE,UAAU;AACZ;;AAEA,UAAU;AACV;EACE,mBAAmB;AACrB;;AAEA,WAAW;AACX;EACE,gBAAgB;AAClB;;AAEA,oBAAoB;AACpB;EACE,gBAAgB;AAClB;;AAEA;AACA,8BAA8B;AAC9B,gBAAgB;AAChB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;AACA,oCAAoC;AACpC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,kCAAkC;AACpC;;;AAGA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GA6BG;;;AAGH;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ;AACF","sourcesContent":["input:focus {\n  outline: none !important;\n  border: none !important;\n}\n.content-modal ::-webkit-scrollbar {\n  width: 3px;\n}\n\n/* Track */\n.content-modal ::-webkit-scrollbar-track {\n  background: #f1f1f1;\n}\n\n/* Handle */\n.content-modal ::-webkit-scrollbar-thumb {\n  background: #888;\n}\n\n/* Handle on hover */\n.content-modal ::-webkit-scrollbar-thumb:hover {\n  background: #555;\n}\n\n.PhoneInputInternationalIconPhone{\n/* display: none !important; */\nmargin-left: 6px;\n}\n\n.PhoneInputInternationalIconGlobe{\n  display: none !important;\n}\n\n.PhoneInputCountry input{\nbackground-color: #F7F7F7 !important;\n}\n\n.PhoneInputInput{\n  background-color: #F7F7F7 !important;\n}\n\n.contact-form .PhoneInputInput{\n  background-color: white !important;\n}\n\n\n/* \n.broadcast video,\nimg {\n  width: 100%;\n  height: auto;\n}\n\n.broadcast video:-webkit-full-screen,\nimg:-webkit-full-screen {\n  width: 100%;\n  height: 100%;\n}\n\n.broadcast video:-moz-full-screen,\nimg:-moz-full-screen {\n  width: 100%;\n  height: 100%;\n}\n\n.broadcast video:-ms-fullscreen,\nimg:-ms-fullscreen {\n  width: 100%;\n  height: 100%;\n}\n\n.broadcast video:fullscreen,\nimg:fullscreen {\n  width: 100%;\n  height: 100%;\n} */\n\n\n.valition{\n  background: black;\n  padding: 10px;\n  border-radius: 10px;\n  vertical-align: middle;\n  display:flex;\n  color:#F7F7F7\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
