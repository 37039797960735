import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import PaymentForm from "../PaymentForm";
import { Input } from "../../../../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { Checkbox } from "../../../../components/ui/checkbox";
import { Button } from "../../../../components/ui/button";
import Layout from "../../../../components/layout/Layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import interceptor from "../../../../hooks/intercepter";
import useToken from "../../../../hooks/useToken";
import toast from "react-hot-toast";
import { GalleryIcon } from "../../../../assets/icons/Icons";
import Loader from "../../../../components/ui/Loader";

// Assets
import postImg from "../../../../assets/images/clemence.png";
import { set } from "lodash";
import { useTranslation } from "react-i18next";

// const stripePromise = loadStripe(
//   "pk_test_51Ok1PQCSI4ixFrp29AWGY7gK5e2DESO8xfrU7LG3PYBd5TcBiiOE7WSTXuXXF7sISF0cEbPpOJBrb6WVvSRRPoNw0038B8WGQv"
// );

const StepThree = (prop) => {
  const { t } = useTranslation("translation");
  const { onSelectedTems, videoThumbnail, packageId, name, image, selectEmail, selectTerms, content } = prop;
  debugger
  const navigate = useNavigate();
  const token = useToken();
  const [id, setId] = useState();
  const [advertiserId, setAdvertiserId] = useState();
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileName, setFileName] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [terms, setTerms] = useState(false);
  const [title, setTitle] = useState("");
  const [contentId, setContentId] = useState("");
  const [packages, setPackages] = useState([]);

  const [clientIntent, setClientIntent] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [formData, setFormData] = useState({
    email: "",
    package: "",
  });
  const [clientSecret, setClientSecret] = useState(null);
  const [duration, setDuration] = useState();
  // const userId = window.location.pathname.split("/")[3];
  const pathSegments = window.location.pathname.split("/");
  const userId = pathSegments[3];
  // const broadcasterId = pathSegments[4];
  const { broadcasterId } = useParams();
  const options = {
    clientSecret: clientSecret,
  };


  useEffect(() => {
    if (packages.length === 0)
      fetchPackages();

    // if (!email) setEmail(selectEmail);
    if (!phoneNumber) setPhoneNumber(phoneNumber);
    if (!terms) setTerms(selectTerms);
  }, []);


  const fetchPackages = async () => {
    try {
      interceptor.axiosGet("get", `admin/package/advertiser?admin=true&broadcaster_id=${broadcasterId}`).then((response) => {
        // interceptor.axiosGet("get", `admin/package`).then((response) => {
        if (response.status === 200) {
          setPackages(response?.data?.data?.result);
          setPackages(response?.data?.data?.result.find((val) => val.id === packageId));
        }
      })
      // const response = await interceptor.axiosGet("get", `admin/package`);
      // if (response.status === 200) {
      //   setPackages(response.data.data.result.find((val) => val.id === packageId));
      // }
    } catch (error) {
      console.error("Failed to fetch packages:", error);
    }
  };


  useEffect(() => {
    if (id) setError(false);
    if (file) setError(false);
    if (formData.email) setError(false);
    if (terms) setError(false);
  }, [id, file, formData.email, terms]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    debugger
    // if (name == 'email') {
    //   setEmail(value);
    //   onSelectedTems({ email: value, terms: terms,  });
    // }
    if (name == 'phoneNumber') {
      setPhoneNumber(value);
      onSelectedTems({ phoneNumber: value, terms: terms, });
    }
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   [name]: value,
    //   package: id,
    // }));
  };


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <section>
            <div className="md:container mx-auto px-5">
              <div className="lg:w-6/12 w-full mx-auto">
                <div className="flex flex-col items-center gap-2">
                  <h4 className="md:text-3xl text-2xl font-bold text-dark text-center">

                    {t("step3")}
                  </h4>
                  <p className="text-sm font-semibold">
                    {t("step3sub")}
                  </p>
                </div>

                <div className="bg-background rounded-xl p-6 flex flex-col gap-5 mt-5">
                  {/* <div>
                    <Select
                      onValueChange={(val) => {
                        setId(val.id);
                        setDuration(val.content_duration);
                      }}
                    >
                      <SelectTrigger className="w-full cursor-pointer bg-white rounded-xl border-none h-[3.063rem]">
                        <SelectValue placeholder="Select Package" />
                      </SelectTrigger>
                      <SelectContent className="bg-white rounded-xl">
                        {packages?.map((val, ind) => {
                          return (
                            <SelectItem
                              key={ind}
                              className="text-sm font-medium text-dark rounded-xl py-2 transition-all duration-500 hover:text-white hover:bg-blue"
                              value={val}
                            >
                              {val.title} - (Duration: {val.content_duration} s,
                              Price: {val.price} usd, Frequency:{" "}
                              {val.content_frequency})
                            </SelectItem>
                          );
                        })}
                      </SelectContent>
                    </Select>
                  </div> */}
                  <h5 className="sm:text-xl text-base font-semibold text-center">
                    {t("step3Detail1")}
                  </h5>
                  <label
                    htmlFor="picture"
                    className="flex flex-col items-center gap-8 sm:p-8 p-4 border border-dashed border-blue bg-background rounded-xl"
                  >
                    <div className="sm:flex gap-4">
                      <div className="text-center flex-shrink-0 flex-grow-0">
                        {(videoThumbnail || image) ? <img
                          src={videoThumbnail ? videoThumbnail : image}
                          width={100}
                          height={100}
                          className="block object-cover rounded-xl sm:w-[7.5rem] sm:h-[7.5rem] w-24 h-24"
                          alt=""
                        /> :
                          <> <div style={{width:'250px', wordWrap:'break-word'}}> {content}</div></>
                        }
                        <span className="text-sm font-normal mt-1 block sm:w-[7.5rem] w-24 whitespace-nowrap overflow-hidden text-ellipsis">
                          {name}
                        </span>
                      </div>
                      <div className="flex flex-col justify-center gap-1 items-center bg-light-orange border-2 border-orange rounded-xl sm:px-4 px-1 sm:py-5 py-2 h-fit w-full">
                        <h6 className="sm:text-base text-sm font-bold text-orange text-center">
                          {packages?.title}
                        </h6>
                        <small className="sm:text-base text-sm font-bold block text-orange">
                          {packages.content_frequency ? packages.content_frequency + ' Diffusions' : packages.duration_in_hours + ' Heure'}

                        </small>
                        <p className="text-sm text-orange text-center">
                          {packages?.content_duration}{t("step3Detail2")}
                        </p>
                      </div>
                    </div>
                    <h6 className="text-lg font-bold">{t("fixprice")} :  {packages.is_free ? 'Free' : packages.price + '€'}</h6>
                    {/* {videoThumbnail ? (
                      <img
                        src={videoThumbnail}
                        alt="Video Thumbnail"
                        className="object-cover w-10 h-10 sm:w-14 sm:h-14 rounded-xl"
                      />
                    ) : imagePreview ? (
                      <img
                        src={imagePreview}
                        alt="Img Preview "
                        className="object-cover w-10 h-10 sm:w-14 sm:h-14 rounded-xl"
                      />
                    ) : (
                      <GalleryIcon
                        width={60}
                        height={60}
                        className="w-10 h-10 sm:w-14 sm:h-14"
                        stroke="#242331"
                      />
                    )}
                    <Input
                      id="picture"
                      className="hidden"
                      type="file"
                      onChange={handleFileChange}
                      accept="image/*,video/*"
                    />
                    <h6 className="text-lg text-center">
                      {file ? (
                        <span>{fileName}</span>
                      ) : (
                        <>
                          drag & drop files here or{" "}
                          <span className="font-semibold text-blue">
                            browse
                          </span>
                        </>
                      )}
                    </h6>*/}

                  </label>
                  {/* {uploadProgress > 0 && (
                    <div className="mt-4">
                      <progress
                        value={uploadProgress}
                        max="100"
                        className="w-full"
                      ></progress>
                      <span>{uploadProgress}%</span>
                    </div>
                  )} */}
                  {/* <div>
                    <Input
                      type="text"
                      className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Title"
                      name="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div> 
                   <div>
                    <Input
                      type="text"
                      className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Email"
                      name="email"
                      onChange={handleChange}
                    />
                  </div>
                  {error && (
                    <div className="pb-4 text-sm font-semibold text-red">
                      {errorMessage}
                    </div>
                  )} */}
                  {/* <div>
                    <Button
                      onClick={handleSubmit}
                      size="sm"
                      className="w-full px-12"
                    >
                      Validate & Pay
                    </Button>
                  </div>  */}
                </div>
                {/* <div className="bg-background rounded-xl p-4 mt-7">
                  <div>
                    <Input
                      type="text"
                      className="text-sm font-medium text-dark bg-white w-full h-[3.063rem] px-5 rounded-xl border-none"
                      placeholder="Entrez votre adresse e-mail"
                      name="email"
                      value={email ? email : ''}
                      onChange={handleChange}
                    />
                  </div>

                  {error && (
                    <div className="pb-4 text-sm font-semibold text-red">
                      {errorMessage}
                    </div>
                  )}
                </div> */}
                <div className="bg-background rounded-xl p-4 mt-7">
                  <div className="flex items-center border rounded-xl h-[3.063rem]">
                    <span className="px-3 text-dark">+33</span>
                    <input
                      type="text" // Changed to "text" to allow leading zeros and spaces
                      className="text-sm font-medium text-dark bg-white w-full h-full px-5 border-none rounded-r-xl"
                      placeholder={t("step3Detail3")}
                      name="phoneNumber"
                      value={phoneNumber}
                      pattern="^(\d{2}\s?){4}\d{2}$" // Matches "06 00 00 00 00"
                      required
                      onChange={(e) => {
                        const input = e.target.value;
                        // Remove any non-numeric or non-space characters
                        const sanitizedInput = input.replace(/[^\d\s]/g, "");
                        // Format the number into "XX XX XX XX XX"
                        const formattedInput = sanitizedInput
                          .replace(/(\d{2})(?=\d)/g, "$1 ")
                          .trim();

                        handleChange({
                          target: {
                            name: e.target.name,
                            value: formattedInput,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex items-center space-x-2 sm:mt-6 mt-4">
                  <Checkbox
                    id="terms"
                    className="rounded border-dark data-[state=checked]:bg-blue data-[state=checked]:text-white data-[state=checked]:border-blue"
                    // checked={terms}
                    checked={terms}
                    // onChange={handleCheckboxChange}
                    onCheckedChange={(e) => {
                      debugger
                      setTerms(e);
                      onSelectedTems({ phoneNumber: phoneNumber, terms: e, });
                    }}
                  />
                  <label
                    htmlFor="terms"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    {t("accept")}{" "}
                    <Link to="/termsOfUse" className="text-blue">
                      {t("condition")}
                    </Link>
                  </label>
                </div>
              </div>
            </div>
          </section>

          {/* {clientSecret && (
            <section className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
              <div className="relative bg-white content-modal p-4 pe-2 rounded-lg shadow-lg">
                <div
                  className="rounded-lg p-4 pe-6"
                  style={{ width: 400, height: 500, overflowY: "scroll" }}
                >
                  <Elements stripe={stripePromise} options={options}>
                    <PaymentForm
                      clientSecret={clientSecret}
                      onPaymentSuccess={handlePaymentSuccess}
                    />
                  </Elements>
                  <button
                    className="absolute top-4 right-4 flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full shadow-md text-gray-600 hover:bg-gray-200"
                    onClick={handleCancelPayment}
                    aria-label="Close"
                  >
                    <svg
                      className="w-4 h-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </section>
          )} */}
        </>
      )}
    </>
  );
};

export default StepThree;
